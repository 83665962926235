import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "docz-example"
    }}>{`Docz example`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.docz.site/docs/introduction"
      }}>{`https://www.docz.site/docs/introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/doczjs/docz"
      }}>{`https://github.com/doczjs/docz`}</a></p>
    <h2 {...{
      "id": "using-create-docz-app"
    }}>{`Using `}<inlineCode parentName="h2">{`create-docz-app`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npx create-docz-app docz-app-basic
# or
yarn create docz-app docz-app-basic
`}</code></pre>
    <h2 {...{
      "id": "download-manually"
    }}>{`Download manually`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl https://codeload.github.com/doczjs/docz/tar.gz/master | tar -xz --strip=2 docz-master/examples/basic
mv basic docz-basic-example
cd docz-basic-example
`}</code></pre>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn # npm i
`}</code></pre>
    <h2 {...{
      "id": "run"
    }}>{`Run`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn dev # npm run dev
`}</code></pre>
    <h2 {...{
      "id": "build"
    }}>{`Build`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn build # npm run build
`}</code></pre>
    <h2 {...{
      "id": "serve-built-app"
    }}>{`Serve built app`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn serve # npm run serve
`}</code></pre>
    <h2 {...{
      "id": "helpful-resources"
    }}>{`Helpful Resources`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet",
        "title": "Markdown Cheatsheet"
      }}>{`Markdown`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      